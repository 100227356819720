import { A, H2 } from '@expo/html-elements';
import { TextProps } from '@expo/html-elements/build/primitives/Text';
import React from 'react';
import {
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import { HoverableLink } from '../components/common/HoverableLink';
import { PrimaryButton } from '../components/common/PrimaryButton';
import { TouchableOpacity } from '../components/common/TouchableOpacity';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { SalonSignup } from '../components/salon/SalonSignup';
import { smBreakpoint } from '../constants';
import { useDimensions } from '../context/DimensionsContext';
import { useThemeState } from '../context/ThemeContext';
import {
  blackBrand,
  GTAmerica,
  GTAmericaCompressed,
  mdbreakpoint,
  PanamaBold,
  redBrand,
  TrailersDemiBold,
  wineBrand,
} from '../libs/constants';

interface Props { }

export const FigureDrawingScreen: React.FC<Props> = () => {
  const { theme } = useThemeState();
  const { width, height } = useDimensions();
  const styles = StyleSheet.create({
    text: {
      color: theme.foregroundColor,
    },
    secondaryHeader: {
      fontFamily: GTAmericaCompressed,
      textTransform: 'uppercase',
      color: theme.foregroundColor,
      fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      fontSize: width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
    },
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.02,
    },
  });
  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <CuratorHeader />
      {/* <Image
        resizeMode="contain"
        source={{ uri: 'https://49market.s3.amazonaws.com/art_cafe_1.png' }}
        style={{
          width: width,
          height: width / 1.333,
          maxWidth: 1560,
          maxHeight: 1170,
          alignSelf: 'center',
        }}
      /> */}
      <View style={{ height: 40 }} />
      <View
        style={{
          justifyContent: 'center',
          width: 700,
          maxWidth: '90vw',
          alignSelf: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: PanamaBold,
            color: theme.foregroundColor,
            textTransform: 'uppercase',
            fontSize:
              width < smBreakpoint ? 22 : width < mdbreakpoint ? 32 : 48,
            letterSpacing: 1.02,
            lineHeight:
              width < smBreakpoint ? 24 : width < mdbreakpoint ? 34 : 50,
          }}
        >
          An Evening of Figure Drawing
        </Text>
        <Text
          style={{
            fontFamily: GTAmerica,
            fontWeight: '300',
            color: theme.foregroundColor,
            fontSize:
              width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
            letterSpacing: 1.07,
          }}
        >
          your guide to Happy Medium's Evening of Figure Drawing
        </Text>
        <View style={{ height: 20 }} />
        {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
            <Text style={styles.secondaryHeader}>Saturday, April 2nd</Text>
            <Text
              style={{
                fontFamily: GTAmerica,
                fontWeight: '300',
                color: theme.foregroundColor,
                fontSize:
                  width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
                letterSpacing: 1.07,
              }}
            >
              12:00 - 2:00PM
            </Text>
          </View>
          <View>
            <HoverableLink disabled={true} linkType="web" linkUrl={''}>
              <Hoverable>
                {(isHovered) => (
                  <View
                    style={{
                      backgroundColor: theme.foregroundColor,
                      paddingVertical: 8,
                      borderRadius: 30,
                      alignItems: 'center',
                      marginTop: 5,
                      // width: '100%',
                      // width: 250,
                      paddingHorizontal: 20,
                      // transform: [{ scale: isHovered ? 1.05 : 1 }],
                      shadowColor: blackBrand,
                      shadowOffset: { width: 5, height: 5 },
                      shadowOpacity: 0.4,
                      shadowRadius: 10,
                    }}
                  >
                    <Text
                      style={[
                        styles.infoText,
                        {
                          textTransform: 'uppercase',
                          fontWeight: '400',
                          color: theme.backgroundColor,
                        },
                      ]}
                    >
                      SOLD OUT
                    </Text>
                  </View>
                )}
              </Hoverable>
            </HoverableLink>
          </View>
        </View> */}
        <View style={{ height: 20 }} />
        <Text style={styles.secondaryHeader}>ARE TICKETS REFUNDABLE?</Text>
        <Text style={[styles.text, styles.body]}>
          No, but as long as you let us know you won't be able to make it at
          least 24 hours before the event start time we're happy to offer you a
          credit that can be applied to any future events, products, or art cafe
          experience.{'\n\n'}
        </Text>
        <Text style={styles.secondaryHeader}>Art tickets Transferable?</Text>
        <Text style={[styles.text, styles.body]}>
          Yes! If you can't make it but want to give your spot to a friend just
          send us an email at reservations@happy-medium.co and we'll be happy to
          help.{'\n\n'}
        </Text>
        <Text style={styles.secondaryHeader}>
          Can I buy more than one ticket?
        </Text>
        <Text style={[styles.text, styles.body]}>
          Yes! On mobile browsers, click "Details" and you'll find the quantity
          selector.{'\n\n'}
        </Text>
        <Text style={styles.secondaryHeader}>
          Will there be tickets available at the door?
        </Text>
        <Text style={[styles.text, styles.body]}>
          No. Tickets must be reserved in advance.{'\n\n'}
        </Text>
        <Text style={styles.secondaryHeader}>
          Can I bring my own food or drinks?
        </Text>
        <Text style={[styles.text, styles.body]}>
          No. No outside food or beverages are allowed.{'\n\n'}
        </Text>
        <Text style={styles.secondaryHeader}>
          How old do you have to be to attend?
        </Text>
        <Text style={[styles.text, styles.body]}>
          Officially we are an 18+ venue for figure drawing. Exceptions to this policy require 1) attendance of the minor's parent or guardian and 2) opt-in from the person who will be modeling during the event. Exception requests should be directed to reservations@happy-medium.co{'\n\n'}
        </Text>
        <Text style={styles.secondaryHeader}>
          Do I need to bring any art supplies?
        </Text>
        <Text style={[styles.text, styles.body]}>
          No. All the supplies you'll need are provided, but you are welcome to
          bring your own as well.{'\n\n'}
        </Text>
        <Text style={styles.secondaryHeader}>Can I bring my animal?</Text>
        <Text style={[styles.text, styles.body]}>
          No. No animals are allowed.{'\n\n'}
        </Text>
        <Text style={styles.secondaryHeader}>Do I need to be an artist?</Text>
        <Text style={[styles.text, styles.body]}>
          Absolutely not - Happy Medium is designed for beginners and,
          specifically, adult beginners. We will send all of our best tips ahead
          of time so you feel like a pro!{'\n\n'}
        </Text>
        <Text style={styles.secondaryHeader}>MORE QUESTIONS?</Text>
        <Text style={[styles.text, styles.body]}>
          Email us at founders@happy-medium.co and we'd be happy to help!
          {'\n\n'}
        </Text>
        <View style={{ height: 60 }} />
      </View>
      <View style={{ height: 60 }} />
      <SalonSignup />
    </ScrollView>
  );
};
