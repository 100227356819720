import React from "react";
import { View, StyleSheet, Text, Image, ScrollView } from "react-native";
import { CuratorHeader } from "../components/curator/CuratorHeader";
import { useThemeState } from "../context/ThemeContext";
import { GTAmerica, PanamaBold } from "../libs/constants";
import { useDimensions } from "../context/DimensionsContext";
import { SalonSignup } from "../components/salon/SalonSignup";
import { HoverableLink } from "../components/common/HoverableLink";
import { RouteComponentProps } from "../navigation";
import { useRef } from "react";
import { smBreakpoint } from "../constants";

interface Props { }

export const ArtCafeScreen: React.FC<
  RouteComponentProps<{
    menu: string;
  }>
> = (props: RouteComponentProps<{ menu: string }>) => {
  document.title = "Art Cafe | Happy Medium";
  const { theme } = useThemeState();
  const { width } = useDimensions();

  const {
    match: {
      params: { menu },
    },
  } = props;

  const scrollToMenu = menu === "menu";

  const scrollRef = useRef<ScrollView>(null);

  // if (scrollToMenu) {
  //   scrollRef.current && scrollRef.current.scrollToEnd({ animated: true });
  // }

  const mdBreakpoint = 860;
  const flowers = require("@happymedium/components/assets/images/arrangement.png");
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.backgroundColor,
      flex: 1,
    },
    body: {
      color: theme.foregroundColor,
      fontFamily: GTAmerica,
      fontSize: width < mdBreakpoint ? 12 : 14,
    },
    header: {
      color: theme.foregroundColor,
      fontFamily: PanamaBold,
      fontSize: width < mdBreakpoint ? 20 : 22,
      letterSpacing: 1.2,
      textTransform: "uppercase",
    },
  });

  return (
    <ScrollView
      ref={scrollRef}
      style={{ backgroundColor: theme.primaryBackgroundColor }}
      onContentSizeChange={() => {
        if (scrollToMenu) {
          // prettier-ignore
          {/* prettier-ignore */ }
          scrollRef.current?.scrollTo(width < smBreakpoint ? 2000 : 3000)
        }
      }}
    >
      <CuratorHeader />
      <View style={[styles.container]}>
        <Image
          style={{ height: width / 1.73, width: width }}
          source={{ uri: "https://49market.s3.amazonaws.com/art_cafe_1.png" }}
        />
        <View
          style={{
            maxWidth: width < mdBreakpoint ? "90%" : 540,
            marginTop: width < mdBreakpoint ? 20 : 60,
            alignSelf: "center",
            // paddingLeft: 20,
            // paddingRight: 20,
            backgroundColor: theme.backgroundColor,
          }}
        >
          <Text
            style={{
              color: theme.foregroundColor,
              fontFamily: PanamaBold,
              fontSize: width < mdBreakpoint ? 24 : 32,
              letterSpacing: 1.2,
              textTransform: "uppercase",
            }}
          >
            Art Cafe
          </Text>
          <View style={{ height: 20 }} />
          <Text style={[styles.body, { fontWeight: "bold" }]}>
            Just like a restaurant but you order art experiences instead of food.
            {"\n\n"}
          </Text>

          <Text style={styles.body}>

            <Text style={{ fontWeight: 'bold' }}>At our Manhattan location - 49 Market St. - we have the following upcoming availabilities:</Text>
            {"\n\n"}

            Thursday 4/3 - 9pm{"\n"}
            Friday 4/4 - 12pm, 2:15pm, and 4:30pm{"\n"}
            Saturday 4/5 - 9pm{"\n"}
            Sunday 4/6 - 12pm and 6:45pm{"\n"}
            {"\n\n"}

            Thursday 4/10 - 9pm{"\n"}
            Friday 4/11 - 12pm, 2:15pm, 4:30pm, 6:45pm, and 9pm{"\n"}
            Saturday 4/12 - 12pm, 4:30pm, 6:45pm, and 9pm{"\n"}
            Sunday 4/13 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            {"\n\n"}

            Thursday 4/17 - 6:45pm and 9pm{"\n"}
            Friday 4/18 - 12pm, 2:15pm, 4:30pm, 6:45pm, and 9pm{"\n"}
            Saturday 4/19 - 12pm, 4:30pm, 6:45pm, and 9pm{"\n"}
            Sunday 4/20 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            {"\n\n"}

            Thursday 4/24 - 6:45pm and 9pm{"\n"}
            Friday 4/25 - 12pm, 2:15pm, 4:30pm, 6:45pm, and 9pm{"\n"}
            Saturday 4/26 - 12pm, 4:30pm, 6:45pm, and 9pm{"\n"}
            Sunday 4/27 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            {"\n\n"}

            <Text style={{ fontWeight: 'bold' }}>At our Brooklyn location - 224 Franklin St. - we have the following upcoming availabilities:</Text>{"\n\n"}

            Tuesday 4/1 - 5pm-10pm{"\n"}
            Wednesday 4/2 - 5pm-10pm{"\n"}
            Thursday 4/3 - 5pm-10pm{"\n"}
            Friday 4/4 - 12pm, 2:15pm, and 4:30pm{"\n"}
            Saturday 4/5 - 6:45pm{"\n"}
            Sunday 4/6 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            {"\n\n"}

            Tuesday 4/8 - 8-10pm{"\n"}
            Wednesday 4/9 - 5pm-10pm{"\n"}
            Thursday 4/10 - 5pm-10pm{"\n"}
            Friday 4/11 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            Saturday 4/12 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            Sunday 4/13 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            {"\n\n"}

            Tuesday 4/15 - 5pm-10pm{"\n"}
            Wednesday 4/16 - 5pm-10pm{"\n"}
            Thursday 4/17 - 5pm-10pm{"\n"}
            Friday 4/18 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            Saturday 4/19 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            Sunday 4/20 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            {"\n\n"}

            Tuesday 4/22 - 5pm-10pm{"\n"}
            Wednesday 4/23 - 5pm-10pm{"\n"}
            Thursday 4/24 - 5pm-10pm{"\n"}
            Friday 4/25 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            Saturday 4/26 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            Sunday 4/27 - 12pm, 2:15pm, 4:30pm, and 6:45pm{"\n"}
            {"\n\n"}

            You can make reservations by{" "}
            <HoverableLink
              textStyle={styles.body}
              linkType="web"
              linkUrl="https://www.instagram.com/gethappymedium/"
            >
              dm
            </HoverableLink>
            {"\n\n"}
            Just like in a restaurant,{" "}
            <Text
              style={[
                styles.body,
                {
                  textDecorationLine: "underline",
                  textDecorationColor: theme.foregroundColor,
                  fontWeight: "bold",
                },
              ]}
            >
              every person orders their own menu item when they arrive
            </Text>
            , we serve you tableside, and clean up the mess when you're done.
            It's the perfect place to create with friends or simply get lost in
            your own creativity. We also have hundreds of art books, art
            objects, and fresh flowers to keep you inspired. Art cafe
            experiences are fully self-guided.
            {"\n\n"}
            The cafe, located on the second floor at 49 Market St. Reservations
            are highly recommended. You can make reservations by{" "}
            <HoverableLink
              textStyle={styles.body}
              linkType="web"
              linkUrl="https://www.instagram.com/gethappymedium/"
            >
              dm
            </HoverableLink>
            . Please include the number of people in your party and the date
            you'd like to join us when you reach out. Please do not follow up
            :) We are a small team and will get back to you as soon as we can. Reservations are for 2 hours from the time of the reservation. We are able
            to accommodate a 10 minute grace period for reservations.
            {"\n\n"}
            Want to bring your whole crew? We're happy to accommodate large
            groups when we have space, but we do require a non-refundable
            deposit of $15 per person when making a reservation for 6 or more
            people. Deposits must be paid in full by one person. The deposit will be applied to your bill at the end of your
            time with us with the exception of no shows. If
            someone can't make it and you let us know at least 24 hours before
            the beginning of your reservation, we're happy to apply their
            deposit to your total bill. If we're not given at least 24 hours'
            notice, the deposit for the no show(s) will be forfeit. We have to
            enforce this policy because spots reserved, but not used, prevent us
            from serving other customers. A 20% service charge will be applied
            to the total bill for all groups of 10 or more. We're not able to accommodate outside food or beverages.
            {"\n\n"}
            Tips are appreciated but not expected. If you'd like to tip, please
            do so in cash. Light drinks and snacks are available for purchase at
            the bar so please, no outside food or beverages. And while we love our
            furry friends, our studio is not pet-friendly so please leave your
            pets at home. No kids under the age of 16 are allowed at Happy
            Medium.
          </Text>
          <View style={{ height: 20 }} />

          <View style={{ height: 40 }} />
          <Text
            style={{
              color: theme.foregroundColor,
              fontFamily: PanamaBold,
              fontSize: width < mdBreakpoint ? 24 : 26,
              letterSpacing: 1.2,
              textTransform: "uppercase",
            }}
          >
            Art Cafe Menu
          </Text>
          <View style={{ height: 20 }} />
          <Image
            style={{
              alignSelf: "center",
              height: width < mdBreakpoint ? width / 1.164 : width / 3 / 1.164,
              width: width < mdBreakpoint ? width : width / 3,
            }}
            source={{
              uri: "https://49market.s3.amazonaws.com/paint-a-pot.png",
            }}
          />
          <View style={{ height: 20 }} />
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.header}>"Paint-a-Pot"</Text>
            <Text style={[styles.header, { textTransform: "lowercase" }]}>
              price of pot + $28pp
            </Text>
          </View>
          <Text style={styles.body}>
            Pick one of our house-made pots (we also have ashtrays, mugs, ring dishes, and more). Decorate it with glaze and then leave it with us for a final firing. End product is food safe and ready for pick up in 1-2 weeks.
          </Text>
          <View style={{ height: 60 }} />
          <Image
            style={{
              alignSelf: "center",
              height: width < mdBreakpoint ? width / 1.164 : width / 3 / 1.164,
              width: width < mdBreakpoint ? width : width / 3,
            }}
            source={{
              uri: "https://49market.s3.amazonaws.com/IMG_9204+1.png",
            }}
          />
          <View style={{ height: 20 }} />

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.header}>Friendship Bracelets</Text>
            <Text style={[styles.header, { textTransform: "lowercase" }]}>
              $28pp
            </Text>
          </View>
          <Text style={styles.body}>
            Like the ones you used to make. Includes supplies to make 2 bracelets and a written guide. Choose the following per bracelet: 2 thread colors - red, orange, yellow, green, light blue, dark blue, purple, pink, black, white. 3 bead colors- red, orange, yellow, green, light blue, dark blue, purple, black, white, multicolor 3 initial charms. Add a pearl charm (+$3) Another color bead (+$2), Shell (+3) or Key Ring (+$2)
          </Text>
          <View style={{ height: 60 }} />
          <Image
            style={{
              alignSelf: "center",
              height: width < mdBreakpoint ? width / 1.164 : width / 3 / 1.164,
              width: width < mdBreakpoint ? width : width / 3,
            }}
            source={{
              uri: "https://49market.s3.amazonaws.com/IMG_3377.jpg",
            }}
          />
          <View style={{ height: 20 }} />
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.header}>Beaded Bracelets</Text>
            <Text style={[styles.header, { textTransform: "lowercase" }]}>
              $80 + a la carte specialty beads
            </Text>
          </View>
          <Text style={styles.body}>
            Select 30 semi-precious, stone, and glass beads of your choice and design your new favorite heirloom bracelet. Includes 30 beads and 1 14.5” gold nylon cord. Includes protective jewelry pouch.
          </Text>
          <View style={{ height: 60 }} />
          <Image
            style={{
              alignSelf: "center",
              height: width < mdBreakpoint ? width / 1.164 : width / 3 / 1.164,
              width: width < mdBreakpoint ? width : width / 3,
            }}
            source={{
              uri: "https://49market.s3.amazonaws.com/clay_experience.jpg",
            }}
          />
          <View style={{ height: 20 }} />
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.header}>The Air Dry Clay Experience</Text>
            <Text style={[styles.header, { textTransform: "lowercase" }]}>
              $28pp
            </Text>
          </View>
          <Text style={styles.body}>
            Come get your hands dirty and play
            with clay. Each experience comes with 1 pound of air dry clay and
            use of ceramic studio tools (sponge, rib, etc.). Like all of our Art
            Cafe experiences, the clay experience is self-guided but we have
            plenty of reference books in our library to guide you.
          </Text>
          <View style={{ height: 60 }} />
          <Image
            style={{
              alignSelf: "center",
              height: width < mdBreakpoint ? width / 1.164 : width / 3 / 1.164,
              width: width < mdBreakpoint ? width : width / 3,
            }}
            source={{ uri: "https://49market.s3.amazonaws.com/watercolor.png" }}
          />
          <View style={{ height: 20 }} />
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.header}>The Watercolor Experience</Text>
            <Text style={[styles.header, { textTransform: "lowercase" }]}>
              $28pp
            </Text>
          </View>
          <Text style={styles.body}>
            Our most popular dish, the watercolor experience includes a full
            plate of our house-made, pure pigment, fresh watercolor paints and a
            pack of our 100% cotton, cold-press watercolor paper. Enjoy the use
            of our studio brushes while we refill your paint water cups so you
            can focus on your next masterpiece.
          </Text>
          <View style={{ height: 60 }} />
          <Image
            style={{
              alignSelf: "center",
              height: width < mdBreakpoint ? width / 1.164 : width / 3 / 1.164,
              width: width < mdBreakpoint ? width : width / 3,
            }}
            source={{
              uri: "https://49market.s3.amazonaws.com/collage_experience.jpg",
            }}
          />
          <View style={{ height: 20 }} />
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.header}>The Collage Experience</Text>
            <Text style={[styles.header, { textTransform: "lowercase" }]}>
              $28pp
            </Text>
          </View>
          <Text style={styles.body}>
            The collage experience is perfect for the members
            of our cut and glue community. Browse our collage bar - filled with
            vintage magazines, beautiful art books, and assorted papers - and
            select your favorite materials. Then spend the afternoon collaging
            your heart out. Use of cutting mat, scissors, X-Acto knife, and
            glues are included.
          </Text>
          <View style={{ height: 60 }} />
          <Image
            style={{
              alignSelf: "center",
              height: width < mdBreakpoint ? width / 1.026 : width / 3 / 1.026,
              width: width < mdBreakpoint ? width : width / 3,
            }}
            source={{ uri: "https://49market.s3.amazonaws.com/charcoal.png" }}
          />
          <View style={{ height: 20 }} />
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.header}>The Charcoal Experience</Text>
            <Text style={[styles.header, { textTransform: "lowercase" }]}>
              $15pp
            </Text>
          </View>
          <Text style={styles.body}>
            Indulge in some sketchiness with our signature charcoal experience.
            It includes Happy Medium charcoal and a sketchbook. A studio cloth,
            erasers, and graphite are available for use in the cafe to enhance
            your charcoal masterpiece.
          </Text>
          <View style={{ height: 60 }} />
          <Image
            style={{
              alignSelf: "center",
              height: width < mdBreakpoint ? width / 1.026 : width / 3 / 1.026,
              width: width < mdBreakpoint ? width : width / 3,
            }}
            source={{ uri: "https://49market.s3.amazonaws.com/pastels.jpg" }}
          />
          <View style={{ height: 20 }} />
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.header}>The Pastel Experience</Text>
            <Text style={[styles.header, { textTransform: "lowercase" }]}>
              $28pp
            </Text>
          </View>
          <Text style={styles.body}>
            Dabble in color with our newest house-made creation. Ours are a
            oil pastel in an always-changing array of colors, developed to
            be easy to blend but also capable of fine details. Includes a
            signature Happy Medium sketchbook.
          </Text>
          <View style={{ height: 60 }} />
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.header}>The Studio Pass</Text>
            <Text style={[styles.header, { textTransform: "lowercase" }]}>
              $28pp
            </Text>
          </View>
          <Text style={styles.body}>
            Have your own supplies and just need some creative space to work?
            Then the studio pass is for you. Each pass unlocks 2 hours of studio
            access including space to work and access to our shop sink.
          </Text>
          <View style={{ height: 60 }} />
          <Text style={styles.header}>Coming Soon</Text>
          <Text style={styles.body}>
            inks, gouache, and more :)
          </Text>
          {/* <View style={{ height: 40 }} />
          <Text style={styles.header}>Full Menu</Text>
          <View style={{ height: 20 }} />
          <Image
            style={{
              alignSelf: 'center',
              height: width < mdBreakpoint ? width / 0.77 : width / 3 / 0.77,
              width: width < mdBreakpoint ? width : width / 3,
            }}
            source={{
              uri: 'https://49market.s3.amazonaws.com/2022-03-02_menu.png',
            }}
          /> */}
          <View style={{ height: 80 }} />
        </View>
      </View>
      <SalonSignup />
    </ScrollView>
  );
};
